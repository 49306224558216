<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div
      class="page__placeholder"
      v-if="
        !user ||
        !applications ||
        !approved_applications ||
        !rejected_applications
      "
    >
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>

    <div
      class="content__page--block"
      v-if="
        user && applications && approved_applications && rejected_applications
      "
    >
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="section__welcome--block">
            <div class="row">
              <div class="col-xl-12">
                <div class="section__welcome section__welcome--dashboard">
                  <div class="section__welcome--row">
                    <div class="section__welcome--left">
                      <div class="section__welcome--title">
                        Добро пожаловать
                      </div>
                      <div class="section__welcome--info">
                        <div class="welcome__info--left">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="welcome__info--right">
                          <div class="welcome__info--name">
                            <span
                              >{{ user.data.surname }}
                              {{ user.data.name }}</span
                            >
                            <!--
														<a :href="'/'+$i18n.locale +'/account/edit'" class="account--link">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</a>
														-->
                          </div>
                          <div class="welcome__info--position">
                            {{ user.data.roles.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section__welcome--right">
                      <div class="section__welcome--info dashboard--welcome">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-received.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Заявлений поступило
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ approved_applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-approved.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Одобрено
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ rejected_applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-rejected.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Отклонено
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="account__infoblock--block"
            v-if="['stationery', 'accountant'].includes(user.data.role.slug)"
          >
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="account__infoblock">
                  <div class="account__infoblock--left">
                    <div class="account__infoblock--img">
                      <img src="../../assets/img/infoblock-2.png" alt="" />
                    </div>
                  </div>
                  <div class="account__infoblock--right">
                    <div class="account__infoblock--top">
                      <div class="account__infoblock--subtitle">
                        Заявление на субсидирование<br />
                        стоимости билета, включенного в туристский продукт, при
                        воздушной перевозке
                      </div>
                      <div class="account__infoblock--title">
                        Несовершеннолетних пассажиров
                      </div>
                    </div>
                    <div class="account__infoblock--body">
                      <div class="section--link">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/administration/applications/kids-go-free/create'
                          "
                          >Подать заявку</a
                        >
                      </div>
                      <div class="account__infoblock--download">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/account/subsiding/kids-go-free'
                          "
                          >Посмотреть правила</a
                        >
                        <a href="/download/кидсгоуфри.pdf" download=""
                          >Скачать pdf</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
							<div class="col-xl-6 col-lg-6">
								<div class="account__infoblock account__infoblock--yellow">
									<div class="account__infoblock--left">
										<div class="account__infoblock--img">
											<img src="../../assets/img/infoblock-1.png" alt="">
										</div>
									</div>
									<div class="account__infoblock--right">
										<div class="account__infoblock--top">
											<div class="account__infoblock--subtitle">
												Заявление на субсидирование<br>
												затрат туроператоров в сфере въездного туризма
											</div>
											<div class="account__infoblock--title">
												За каждого иностранного туриста
											</div>
										</div>
										<div class="account__infoblock--body">

											<div class="section--link">
												<a :href="'/'+ $i18n.locale +'/account/tourist-add'">Подать заявку</a>
											</div>
											<div class="account__infoblock--download">
												<a :href="'/'+$i18n.locale +'/account/subsiding/foreign-tourist'">Посмотреть правила</a>
												<a href="/download/за каждого интуриста.pdf" download="">Скачать pdf</a>
											</div>

										</div>
									</div>
								</div>
							</div>
            </div>
          </div> -->

          <div class="registry__table">
            <div class="registry__tabs">
              <div
                class="registry__tabs--item"
                v-bind:class="{ 'registry__tabs--active': Categories == 'all' }"
                @click="categoriesTab('all')"
              >
                <a href="#">Все заявления</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'foreign',
                }"
                @click="categoriesTab('foreign')"
              >
                <a href="#">Иностранные туристы</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'kids',
                }"
                @click="categoriesTab('kids')"
              >
                <a href="#">Несовершеннолетние пассажиры</a>
              </div>
            </div>

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">
                  Реестр поступивших заявлений
                </div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        placeholder="Поиск по таблице"
                      />
                      <button>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.0004 21L16.6504 16.65"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Статус"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div style="width: 20px; height: auto; float: left"></div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="period"
                      :options="periods"
                      label="title"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Период"
                      :hideSelected="true"
                    >
                      <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                        @click="onClickPeriod(option)"
                        >{{ option.title }}</template
                      >
                    </multiselect>
                  </div>

                  <div style="width: 20px; height: auto; float: left"></div>

                  <div>
                    <!--<div v-for="item in periods" style="padding: 5px 15px; float: left;" @click="onClickPeriod(option)">{{ item.title }}</div>-->
                    <input
                      type="date"
                      v-model="another_period_start"
                      v-if="period && period.value == 'another'"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        margin-left: 0px;
                        margin-right: 5px;
                        border-radius: 5px;
                        padding: 0 10px;
                      "
                    />
                    <input
                      type="date"
                      v-model="another_period_end"
                      v-if="period && period.value == 'another'"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        margin-left: 5px;
                        margin-right: 0px;
                        border-radius: 5px;
                        padding: 0 10px;
                      "
                    />
                    <!--<div @click="onClickDownloadReport">Скачать отчет</div>-->
                  </div>

                  <div>
                    <div style="width: 250px; height: 40px; float: left">
                      <div class="section--link" style="margin: 10px 0">
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-if="generate_report_url"
                          target="_blank"
                          :href="
                            'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=pdf&' +
                            generate_report_url.join('&')
                          "
                          >Скачать отчет PDF</a
                        >
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-else
                          target="_blank"
                          :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=pdf'"
                          >Скачать отчет PDF</a
                        >
                      </div>
                    </div>
                    <div style="width: 250px; height: 40px; float: left">
                      <div class="section--link" style="margin: 10px 0">
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-if="generate_report_url"
                          target="_blank"
                          :href="
                            'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=excel&' +
                            generate_report_url.join('&')
                          "
                          >Скачать отчет Excel</a
                        >
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-else
                          target="_blank"
                          :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=excel'"
                          >Скачать отчет Excel</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--
									<div>
										<div style="width: 250px; height: 40px; float: left;">
											<div class="section--link">
												<a style="padding-top: 12px; padding-bottom: 12px;" v-if="generate_report_url" target="_blank" :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?' + generate_report_url.join('&')">Скачать отчет</a>
												<a style="padding-top: 12px; padding-bottom: 12px;" v-else target="_blank" :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report'">Скачать отчет excel</a>
											</div>
										</div>
									</div>
									-->
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{ getStatusText(item.status) }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <td>
                      <a
                        class="tb__table--btn"
                        v-if="item.category_id == 'foreign'"
                        :href="
                          '/' +
                          $i18n.locale +
                          '/administration/applications/tourist-views/' +
                          item.id +
                          '/edit'
                        "
                        style="
                          margin: 0 auto;
                          display: block;
                          width: 21px;
                          height: 21px;
                        "
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.875 10.5C0.875 10.5 4.375 3.5 10.5 3.5C16.625 3.5 20.125 10.5 20.125 10.5C20.125 10.5 16.625 17.5 10.5 17.5C4.375 17.5 0.875 10.5 0.875 10.5Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                      <a
                        class="tb__table--btn"
                        v-if="item.category_id == 'kids'"
                        :href="
                          '/' +
                          $i18n.locale +
                          '/administration/applications/kids-go-free/' +
                          item.id +
                          '/edit'
                        "
                        style="
                          margin: 0 auto;
                          display: block;
                          width: 21px;
                          height: 21px;
                        "
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.875 10.5C0.875 10.5 4.375 3.5 10.5 3.5C16.625 3.5 20.125 10.5 20.125 10.5C20.125 10.5 16.625 17.5 10.5 17.5C4.375 17.5 0.875 10.5 0.875 10.5Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </td>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error"
                    >Ваш поиск по запросу "{{ filters.search }}" не дал
                    результатов.</v-alert
                  >
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'Account',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      applications: null,
      approved_applications: null,
      rejected_applications: null,
      user: null,
      generate_report_url: null,
      periods: [
        {
          title: 'Все',
          value: 'all',
        },
        {
          title: 'Месяц',
          value: 'last month',
        },
        {
          title: 'Квартал',
          value: 'last 3 months',
        },
        {
          title: 'Полугодие',
          value: 'last 6 months',
        },
        {
          title: 'Год',
          value: 'last year',
        },
        {
          title: 'Другой период',
          value: 'another',
        },
      ],
      period: null,
      another_period_start: null,
      another_period_end: null,
      orderList: [
        {
          status: 'all',
          name: 'Все',
        },
        {
          status: 'sent-by',
          name: 'Поступившие',
        },
        {
          status: 'registered',
          name: 'Зарегистрированные',
        },
        {
          status: 'resend',
          name: 'Возвращенные',
        },
        {
          status: 'denied',
          name: 'Отказанные',
        },
        {
          status: 'in-work',
          name: 'В работе',
        },
        {
          status: 'approved',
          name: 'Одобренные',
        },
        {
          status: 'paid',
          name: 'Выплачено',
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: 'Все',
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Название туроператора',
          value: 'applicant_name',
        },
        {
          text: 'ФИО туриста/пассажира',
          value: 'tourist',
        },
        {
          text: 'ВИД заявления',
          value: 'type',
          width: '200px',
        },
        {
          text: 'Дата и время',
          value: 'datetime',
        },
        {
          text: 'Статус',
          value: 'status',
        },
        {
          text: 'Действие',
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    getStatusText() {
      return (item) => {
        return (
          this.orderList.find((e) => e.status === item.slug)?.name || item.name
        )
      }
    },
    filteredlist() {
      //	console.log(this.filterCategories);

      return this.applications.data.filter((d) => {
        if (
          d.view.name
            .toLowerCase()
            .includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status.slug == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }

        /*
					if ((d.view.name.toLowerCase().includes(this.filters.search.toLowerCase()) || d.tourist.toLowerCase().includes(this.filters.search.toLowerCase()) || d.date.toLowerCase().includes(this.filters.search.toLowerCase())) && (d.status.slug.toLowerCase().includes())) {
						return true;
					}
					*/
      })
    },

    listenChange() {
      const { period, filterCategories } = this
      return { period, filterCategories }
    },
  },
  methods: {
    getApplications() {
      this.generate_report_url = []

      if (this.period && this.period.value != 'all')
        this.generate_report_url.push('filter[period]=' + this.period.value)
      if (this.period && this.period.value == 'another')
        this.generate_report_url.push(
          'filter[another_date_start]=' + this.another_period_start,
        )
      if (this.period && this.period.value == 'another')
        this.generate_report_url.push(
          'filter[another_date_end]=' + this.another_period_end,
        )
      if (this.filterCategories && this.filterCategories.status != 'all')
        this.generate_report_url.push(
          'filter[status]=' + this.filterCategories.status,
        )

      api
        .get('applications', {
          params: {
            'filter[period]': this.period ? this.period.value : null,
            'filter[another_period_start]':
              this.period && this.period.value == 'another'
                ? this.another_period_start
                : null,
            'filter[another_period_end]':
              this.period && this.period.value == 'another'
                ? this.another_period_end
                : null,
            'filter[status]':
              this.filterCategories && this.filterCategories.status != 'all'
                ? this.filterCategories.status
                : null,
          },
        })
        .then((response) => {
          if (response.data) {
            this.applications = response.data
          }
        })
    },
    categoriesTab(category) {
      this.Categories = category

      if (category == 'foreign') this.headers[1].text = 'ФИО туриста'
      if (category == 'kids') this.headers[1].text = 'ФИО пассажира'
      if (category == 'all') this.headers[1].text = 'ФИО туриста/пассажира'
    },
    filterTab(category) {
      this.filterCategories = category

      //	console.log(category);
    },
    onClickPeriod(item) {
      this.period = item

      if (
        ['last month', 'last 3 months', 'last 6 months', 'last year'].includes(
          item.value,
        )
      ) {
        this.getApplications()
      }
    },
    onClickDownloadReport() {
      api
        .get('applications/generate-report', {
          params: {
            'filter[period]': this.period ? this.period.value : null,
            'filter[another_period_start]':
              this.period && this.period.value == 'another'
                ? this.another_period_start
                : null,
            'filter[another_period_end]':
              this.period && this.period.value == 'another'
                ? this.another_period_end
                : null,
          },
        })
        .then((response) => {
          if (response.data) {
            this.applications = response.data
          }
        })
    },
  },
  watch: {
    listenChange() {
      this.getApplications()
    },
    another_period_start() {
      if (this.another_period_start && this.another_period_end) {
        this.getApplications()
      }
    },
    another_period_end() {
      if (this.another_period_start && this.another_period_end) {
        this.getApplications()
      }
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/' + this.$i18n.locale + '/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api
      .get('applications', {
        params: {},
      })
      .then((response) => {
        if (response.data) {
          this.applications = response.data
        }
      })

    api
      .get('applications', {
        params: {
          'filter[status]': 'approved',
        },
      })
      .then((response) => {
        if (response.data) {
          this.approved_applications = response.data
        }
      })

    api
      .get('applications', {
        params: {
          'filter[status]': 'denied',
        },
      })
      .then((response) => {
        if (response.data) {
          this.rejected_applications = response.data
        }
      })
  },
}
</script>

<style scoped></style>
